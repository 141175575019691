import Link from 'next/link'
import { Alert } from '../Icons'

type ReservationHasAccountProps = {
  reservation?: string
}

export default function ReservationHasAccount({
  reservation
}: ReservationHasAccountProps) {
  return (
    <div className="flex flex-col items-center gap-4">
      <Alert />
      <h1 className="text-center text-lg font-serif font-bold text-[27px]">
        Reserva já associada a uma conta Charlie.
      </h1>
      <p className="text-center text-sm">
        Para enviar seus documentos para check-in faça seu login clicando{' '}
        <Link
          href={
            `${process.env.NEXT_PUBLIC_SITE_URL}?openLogin=true` +
            `${reservation && `&reserva=${reservation}`}`
          }
        >
          aqui
        </Link>
      </p>
    </div>
  )
}
